@import '~@gcl/react-core/dist/themes.scss';

.gcl-borehole-log-admin-layout-outlet {
  border-radius: 4px;
  border: 1px solid $color-greyscale-300;
  height: calc(100vh - 200px);
  padding: $spacing-sm;
}

.gcl-borehole-log-admin-menu-container {
  @extend .gcl-padding-vertical-sm;
  padding-right: $spacing-sm;
  margin-right: $spacing-sm;
}
