@import '~@gcl/react-core/dist/themes.scss';

.gcl-borehole-log-api-error-display-container {
  display: flex;
  flex-direction: column;
  width: 100%;

  button {
    align-self: flex-end;
  }
}
