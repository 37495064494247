@import '~@gcl/react-core/dist/themes.scss';

.gcl-note-container {
  background-color: $color-greyscale-200;
  border-left: 4px solid;
  border-radius: 4px;
  box-sizing: border-box;
  width: fit-content;
  display: inline-block;

  .MuiGrid-item {
    align-items: center;
  }
}
