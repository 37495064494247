@import '~@gcl/react-core/dist/themes.scss';

.gcl-borehole-log-label-with-message {
  display: flex;
  align-items: center;

  .gcl-alert-message.gcl-borehole-log-label-with-message-message {
    padding: $spacing-md;
    margin: $spacing-sm 0px;

    .gcl-alert-message-header {
      font-size: $font-size-md;
    }
    .gcl-alert-message-content {
      font-size: $font-size-sm;
    }
  }

  .gcl-borehole-log-label-with-message-label.gcl-label {
    padding-top: 0;
    font-weight: bold;
    padding-bottom: 0;
    span {
      display: flex;
    }
  }
}
