@import '~@gcl/react-core/dist/themes.scss';

.borehole-map-table {
  width: 100%;
  border-collapse: collapse;
  th {
    border: 1px solid $color-greyscale-400;
    padding: 8px;
    text-align: left;
  }
  td {
    min-width: 100px;
    border: 1px solid $color-greyscale-400;
    padding: $spacing-xxs;
    text-align: left;
  }
}
