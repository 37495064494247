@import '~@gcl/react-core/dist/themes.scss';

.gcl-borehole-log-layout-container {
  #gcl-layout-top-nav-container {
    height: 100px;
  }

  #gcl-layout-hsinglenav-content-container {
    padding: 0px;
    margin: 0px;
    max-width: unset;
    overflow-y: hidden;
    display: flex;
    flex-direction: column;
  }

  #gcl-layout-main-container {
    width: 100%;
  }

  .gcl-layout-collapsablenav-item {
    padding: $spacing-xs 0;
  }

  .gcl-borehole-log-layout-container-username {
    display: none;
  }
}
.gcl-borehole-log-layout-container.gcl-borehole-log-block-action {
  .gcl-layout-hsinglenav-item-container {
    pointer-events: none;
  }
  .gcl-layout-hsinglenav-bottom-nav-container {
    pointer-events: none;
  }
}
.gcl-borehole-log-layout-setting {
  position: relative;
}

.gcl-borehole-log-layout-top-nav-grid-common {
  & > * {
    @extend .gcl-px-md;

    border-left: 1px solid $color-greyscale-400;
    height: 20px;
    align-items: center;
    display: flex;
    text-align: center;
  }

  & > :first-child {
    border-left: 0px;
  }

  .gcl-borehole-log-layout-link {
    @extend .gcl-clickable;
    &:hover {
      color: $color-font-link;
    }
  }

  .gcl-borehole-log-layout-dropdown-btn {
    padding-right: 0px;
    padding-left: 0px;
    border-radius: 0px !important;

    button.dropdown-toggle.btn {
      @extend .gcl-px-md;
      background-color: transparent;
      border-color: transparent;

      &:hover {
        background-color: transparent;
        border-color: transparent;
        color: $color-font-link;
      }
      &:focus {
        background-color: transparent;
        border-color: transparent;
        box-shadow: none;
      }
    }
  }
}

.gcl-borehole-log-top-nav-container {
  display: flex;
  @extend .gcl-p-sm;
  border-bottom: 1px solid $color-greyscale-400;

  .gcl-borehole-log-layout-top-nav-logo {
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    white-space: nowrap;

    img {
      max-height: 22px;
      object-fit: cover;
    }
    h5 {
      margin: 0px;
      font-weight: 600;
    }
  }

  .gcl-borehole-log-layout-top-nav-grid {
    @extend .gcl-borehole-log-layout-top-nav-grid-common;
    flex-wrap: wrap;
    width: auto;

    & > * {
      @extend .gcl-my-xxs;
    }
  }

  .gcl-input-group-container {
    @extend .gcl-my-sm;
  }
}

.gcl-borehole-log-top-nav-container-mobile {
  .gcl-borehole-log-layout-top-nav-grid {
    @extend .gcl-borehole-log-layout-top-nav-grid-common;
    border-bottom: 0px;

    & > * {
      justify-content: center;
      flex: 1;
    }
  }
}

.gcl-borehole-log-layout-right-nav-opened {
  margin-right: 300px;
}

.gcl-borehole-log-layout-global-page-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.gcl-borehole-log-layout-outlet {
  @extend .gcl-p-lg;
}
