.responsive-borehole-table {
  max-height: calc(100vh - 284px);
  max-width: 100%;
  overflow: auto;
  margin-top: 24px;
  border: 1px solid lightgrey;
  font-size: 14px;
}

.gcl-button-zip-all {
  display: block;
}
