@import '~@gcl/react-core/dist/themes.scss';

.gcl-borehole-log-require-login-container {
  @extend .gcl-p-sm;
  display: flex;

  align-items: center;
  min-height: 100vh;
  justify-content: center;
  background-color: $color-greyscale-200;
}
.gcl-borehole-log-require-login-content {
  @extend .gcl-p-xl;
  display: flex;
  flex-direction: column;
  background-color: white;
  align-items: center;
  border-radius: 5px;
}

.gcl-borehole-log-require-login-logo {
  @extend .gcl-pb-lg;
  @extend .gcl-mb-lg;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid $color-greyscale-600;
}
.gcl-borehole-log-require-login-text {
  @extend .gcl-mb-lg;
  @extend .gcl-mx-lg;
  text-align: center;
}
