@import '~@gcl/react-core/dist/themes.scss';

.gcl-sort-icon {
  border: 1px solid $color-greyscale-600;
  border-radius: 15%;
}

.gcl-borehole-log-grid-container {
  .gcl-borehole-log-grid-row {
    flex-wrap: nowrap;
    @extend .gcl-py-xxs;
    border-bottom: 1px solid $color-greyscale-300;
  }
  .gcl-borehole-log-grid-row > * {
    @extend .gcl-pr-xxs;
  }
  .gcl-borehole-log-grid-row:nth-child(odd) {
    background-color: $color-greyscale-200;
  }
  .gcl-borehole-log-grid-row:nth-child(even) {
    background-color: #ffffff; /* This is for even rows */
  }
  .gcl-borehole-log-grid-row:hover {
    background-color: $color-greyscale-500;
  }
}
.gcl-borehole-log-grid-header {
  font-weight: bold;
}

.gcl-borehole-log-grid-row-item {
  overflow-wrap: anywhere;
}
.gcl-borehole-log-grid-action {
  width: 60px;
  flex-direction: row;
  display: flex;
}
.gcl-borehole-log-grid-action:first-of-type {
  display: block;
}
