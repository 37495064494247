.leaflet-container {
  position: relative;
  border: 1px solid black;
  border-radius: 8px;
  height: calc(100vh - 100px);
  width: 100%;
}

.sr-only {
  display: none;
}

.borehole-map {
  .leaflet-pane {
    z-index: 99;
  }
}
